export const en = {
  menu: 'Menu',
  home: 'Home',
  about: 'About',
  portfolio: 'Porfolio',
  contact: 'Contact',
  play: 'Play',
  exit: 'Exit',
  new: 'New',
  tictactoeTitle: 'Tictactoe',
  sudokuTitle: 'Sudoku',
  congratsWinner: "Congrat's Player {player}, you just won!",
  playerOne: 'One',
  playerTwo: 'Two',
  playerCurrentMove: "Player {player}'s move",
  draw: "Look's like it's a draw!",
  roleTitle: 'Fullstack software developer',
  name: 'Willwin Wang',
  forwardArrow: 'Forward arrow',
  backArrow: 'Back arrow',
  email: 'email',
  linkedIn: 'linkedIn',
  gitHub: 'gitHub',
  twitter: 'twitter',
  aboutDescription: 'Live. Eat. Code. Dance. Sleep. Repeat.',
  typescript: 'typescript',
  python: 'python',
};
